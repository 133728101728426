import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
import { List } from 'flowbite-react'
import { HiCheckCircle } from 'react-icons/hi'
export default function Datacenter() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 bg-darl-800">
        <div>
          {' '}
          <div className="lg:px-20 p-5 text-center">
            <h2 className="text-2xl">DATA CENTERS</h2>
          </div>
          <div className="bg-white  text-slate-700 flex justify-around text-center  font-semibold  flex-col text-lg py-50">
            <h2 className="mx-auto text-center">Our Datacenters</h2>
            <p className="font-light p-5">
              Disclaimer: These datacenters represent only a fraction of the
              mining operations we own. They are not shown due to customer
              protection and security being a high priority for us. We can only
              film footage and set up live feeds at locations that are not
              critical and we do so without disclosing sensitive information or
              critical intellectual property.
            </p>
            <div className="font-light p-5">
              <h2 className="font-semibold">Enigma Datacenter</h2>
              Enigma is one of the largest cryptocurrency mining facilities in
              the world. First built to exclusively mine Ethereum, the facility
              is being continuously upgraded for mining state-of-the-art
              Blockchains. Enigma’s computational performance is achieved with
              specifically designed Rigs that efficiently mine hashing
              algorithms for various cryptocurrencies such as Zcash, Dash,
              Litecoin, Bitcoin, Ethereum and others. The Enigma facility is
              powered by geothermal energy, and resides in the capital of
              Iceland.
            </div>
            <img src="/asset/img/datacenter/d1.png" className="w-full" />
            <div className="font-light p-5">
              <h2 className="font-semibold">Thor Datacenter</h2>
              The Thor data center in Sweden is our answer to the massive growth
              of the mining industry worldwide. It features significant
              improvements in design and capacity over our previous facilities
              and represents the latest iteration of our datacenters. A cool
              climate and plentiful renewable hydro power makes Sweden an
              extremely attractive and competitive hosting location. We are
              looking forward to expanding this operation to one of the biggest
              world wide.
            </div>
            <img src="/asset/img/datacenter/d2.png" className="w-full" />
          </div>
        </div>
        <h2 className="text-2xl text-center mx-auto">
          Datacenter Specifications
        </h2>
        <p className="font-light p-5">
          Our datacenter operations provide a secure and resilient hosting
          environment in Iceland. The facility has a redundant, high capacity
          UPS supply and a diesel generator on site for backup. Fully redundant
          indirect adiabatic air cooling system, configured to a hot aisle/cold
          aisle design is installed to monitor and control the temperature and
          prevent overheating.
        </p>
        <div className="grid lg:grid-cols-3 grid-cols-1">
          <div className="flex flex-col gap-2">
            {' '}
            <i className="material-icons notranslate text-6xl mx-auto">waves</i>
            <p className="font-semibold mx-auto">Security</p>
            <p className="font-light p-5">
              The site provides a secure operating environment for our
              equipment. It is subject to strict data management, security and
              confidentiality requirements and only accessible to authorized
              personnel. Additionally, all activities are monitored through
              cameras and our in-house built management software, Hive, gives us
              full overview on the devices (soft- and hardware) and their
              performance.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            {' '}
            <i className="material-icons notranslate text-6xl mx-auto">dns</i>
            <p className="font-semibold mx-auto">Connectivity</p>
            <p className="font-light p-5">
              Situated midway between Europe and the United States, running our
              servers in Iceland makes it possible to transmit information with
              low latency to both continents. Producing and propagating
              blockchain blocks in a reliable matter is of utmost importance to
              us as a mining business. Our data centers are already equipped
              with high performance high-bandwidth solutions..
            </p>
          </div>
          <div className="flex flex-col gap-2">
            {' '}
            <i className="material-icons notranslate text-6xl mx-auto">
              payment
            </i>
            <p className="font-semibold mx-auto">Scaling</p>
            <p className="font-light p-5">
              Due to our long-term experience building and maintaining data
              centers around the world we will be able to adapt and scale to
              increasing sizes in the shortest time possible.
            </p>
          </div>
        </div>
        <div className="bg-white text-slate-700 mx-auto px-5 py-10 text-center">
          <p className="text-xl font-semibold text-center">
            Device Specifications
          </p>
          <p>
            Disclaimer: These server specifications are our initial setup and
            are subject to change
          </p>
          <p>Owned servers in owned datacenter. Deployed hardware:</p>
          <List className="bg-dark-500 text-slate-800 flex flex-col gap-5">
            <List.Item icon={HiCheckCircle}>
              Dell R740 8 x 2.5” Bay - Chassis
            </List.Item>
            <List.Item icon={HiCheckCircle}>
              Dell Intel X710 I350 Quad Port 10GB Network Card
            </List.Item>
            <List.Item icon={HiCheckCircle}>
              Dual Intel Xeon Platinum 8180M 2.50GHz 28-Core 1.5TB DDR4 RAM
            </List.Item>
            <List.Item icon={HiCheckCircle}>
              Dell H740P 8GB NV Cache PCI-E Raid Controller
            </List.Item>
            <List.Item icon={HiCheckCircle}>
              2 x Dell 2.5” 3.84TB 12GBPS SAS SSD Drives
            </List.Item>
            <List.Item icon={HiCheckCircle}>
              2 x Dell 2.5” 3.84TB 12GBPS SAS SSD Drives
            </List.Item>
            <List.Item icon={HiCheckCircle}>
              2 x 1100W Power Supply IDRAC 9 Enterprise
            </List.Item>
          </List>
          <p>Same configuration for any backup infrastructure.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
