import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { BiCopyAlt } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
export default function Farming({
  coins,
  user,
  curData,
  pendingyields,
  yplans,
  getyPlans,
  getPendingYields,
  logout,
}) {
  const [navState, setNavState] = useState('hide')
  const [addr, setAddr] = useState('')
  const [tid, setTid] = useState('')
  const [pop, setPop] = useState('')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  useEffect(() => {
    getyPlans()
    getPendingYields()
  }, [])
  useEffect(() => {
    if (pendingyields?.amount > 0) {
      setAddr(pendingyields?.paddress)
      setTid(pendingyields?.id)
    }
  }, [pendingyields, yplans, addr, tid])
  const Copy = (a) => {
    navigator.clipboard.writeText(a)
    Swal.fire('WALLET COPIED', a, 'success')
  }
  const uploadPop = (e) => {
    e.preventDefault()
    var formData = new FormData(e.target)
    axios
      .post(`${process.env.REACT_APP_SERVER}/?r=ypop&id=${tid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response?.data?.msg) {
          Swal.fire(
            'PAYMENT RECEIPT',
            response?.data?.msg,
            response?.data?.type,
          )
        }
        if (response?.data?.pop) {
          setPop(response?.data?.pop)
        }
      })
      .catch((error) => console.error(error))
  }
  const closeTrnx = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=closefarm'}`,
      withCredentials: false,
      data: {
        tid: tid,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        if (response.data.type === 'success') {
          setTid('')
          setAddr('')
        } else {
          Swal.fire(response.data.msg)
        }
        window?.location?.reload()
      })
      .catch((error) => console.error(error))
  }

  const farm = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER}/?r=farm`,
      data: new FormData(e.target),
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        if (response?.data?.msg) {
          Swal.fire(response?.data?.msg)
        } else if (response?.data?.address) {
          setAddr(response.data.address)
          setTid(response.data.id)
          setPop(response.data.pop)
        }
      })
      .catch((error) => console.error(error))
  }

  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/dashboard">Dashboard</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/yield/deposit">Yield</Link>
        </div>
        <h2 className="text-2xl mx-auto text-center font-semibold my-3">
          YIELD CONTRACTS
        </h2>
        {(addr === '' || tid === '') && (
          <div className="mb-[200px] grid lg:grid-cols-3 grid-cols-1 gap-5 text-slate-100 mx-auto w-full">
            {yplans.length > 0 &&
              yplans.map((c, k) => (
                <div title={c.coin} key={k} className="mx-10">
                  <div className="flex flex-col gap-1 mx-auto text-center">
                    <div className="px-10 w-full flex flex-col bg-slate-800  py-6 border-2 border-slate-400 justify-center">
                      <div className="flex flex-col py-3 text-center">
                        <span className="text-2xl font-semibold my-2">
                          {c.plan} Plan
                        </span>
                      </div>
                      <div className="flex justify-center"></div>
                      <div className="flex flex-row gap-2 justify-center w-full text-lg font-semibold  border-slate-300 py-3">
                        <span className="text-center my-auto">MIN</span>
                        <span className="text-center my-auto">
                          {user.cur + Number(c.min)?.toPrecision(4)}
                        </span>
                      </div>

                      <div className="w-full text-2xl font-semibold border-t-2 border-slate-300 py-3 text-sm"></div>
                      <div className="flex flex-row gap-2 justify-center w-full text-lg font-semibold  border-slate-300 py-3">
                        <span className="text-center my-auto">APY</span>
                        <span className="text-center my-auto">
                          {Number(c.apy)?.toPrecision(3)}%
                        </span>
                      </div>

                      <form method="post" onSubmit={(e) => farm(e)}>
                        <label>Lever</label>
                        <input
                          className="bg-transparent rounded-lg my-3 w-full"
                          name="lever"
                          type="number"
                          step={0.5}
                          min="1.5"
                          max={c.lever}
                          defaultValue={c.lever}
                        />
                        <label>Amount</label>
                        <input
                          className="bg-transparent rounded-lg my-3 w-full"
                          name="amount"
                          type="number"
                          min={c.min}
                          defaultValue={c.min}
                        />
                        <input name="plan" type="hidden" value={c.plan} />{' '}
                        <label>Lock Period</label>
                        <input
                          className="bg-transparent rounded-lg my-3 w-full"
                          name="duration"
                          type="number"
                          step={1}
                          min="7"
                          max={730}
                          defaultValue={30}
                        />
                        <label>COIN TO DEPOSIT</label>
                        <select
                          className="bg-transparent rounded-lg my-3 w-full"
                          name="type"
                        >
                          <option>BTC</option>
                          <option>ETH</option>
                        </select>
                        <button className="bg-blue-500 py-2 px-6 font-semibold text-white mx-auto rounded-sm w-[80%] text-center">
                          {' '}
                          Farm
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              ))}

            {yplans.length < 1 && (
              <div className="mx-auto w-full p-2 bg-blue-300 text-white">
                No Active Yield Contracts yet.
              </div>
            )}
          </div>
        )}
        {addr !== '' && tid !== '' && (
          <div className="flex text-slate-100 mx-auto lg:w-[60%] w-full mx-auto px-3 py-10">
            <div className="px-10 w-full flex flex-col bg-slate-800  py-6 border-2 border-slate-400 justify-center">
              {
                <div className="flex flex-col py-3 text-center border-1 border-slate-400">
                  <span className="text-lg font-light my-2">
                    SEND{' '}
                    <span className="font-bold">
                      {Number(
                        pendingyields?.amount *
                          coins?.filter(
                            (c) =>
                              c?.symbol.toLowerCase() ===
                              pendingyields?.pcur?.toLowerCase(),
                          )[0]?.rate,
                      ).toPrecision(6)}
                    </span>{' '}
                    {pendingyields?.pcur} TO THE WALLET ADDRESS BELOW OR SCAN
                    THE QR CODE WITH YOUR WALLET APP
                  </span>
                </div>
              }
              {addr !== '' && (
                <>
                  <div className="flex justify-end mr-[-5px] text-red-500 hover:text-red-300">
                    {' '}
                    {pop === '' && (
                      <span
                        className="cursor-pointer"
                        onClick={(e) =>
                          Swal.fire({
                            title:
                              'Are you sure you want to Cancel Transaction',
                            showCloseButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'Cancel',
                            cancelButtonText:
                              "<span class='text-green-400'>Back</span>",
                          }).then((result) => {
                            if (result.isConfirmed) {
                              closeTrnx(e)
                            }
                          })
                        }
                      >
                        Cancel Transcation
                      </span>
                    )}
                    {pop !== '' && <Link to="/office/dashboard">Back</Link>}
                  </div>{' '}
                  <p className="mx-auto">Pay With</p>
                  <p className="bg-blue-300 text-white p-3 text-center flex gap-3 justify-center">
                    <span>{addr}</span>
                    <BiCopyAlt
                      className="text-xl cur"
                      title="Click to copy"
                      onClick={() => Copy(addr)}
                    />
                  </p>
                  <div className="flex flex-col py-3 text-center gap-2">
                    <div className="py-5 px-2 lg:w-[250px] w-[160px] text-center mx-auto">
                      <img
                        alt={addr}
                        src={`${process.env.REACT_APP_SERVER}/?r=qr&addr=${addr}`}
                        className="w-full h-full"
                      />
                    </div>
                    {pop === '' && (
                      <form
                        method="post"
                        encType="multipart/form-data"
                        onSubmit={(e) => uploadPop(e)}
                      >
                        <input
                          type="file"
                          name="pop"
                          className="border-2 border-slate-300 rounded-lg p-1 my-2"
                        />
                        <button
                          type="submit"
                          className="py-5 px-2 w-full border-2 border-slate-100 bg-slate-600 text-center "
                        >
                          UPLOAD PAYMENT RECEIPT
                        </button>
                      </form>
                    )}
                    {pop !== '' && (
                      <>
                        <p className="bg-green-400 p-2 mx-auto">
                          PAYMENT PENDING APPROVAL.YOU WILL BE NOTIFIED ONCE
                          APPROVED.
                        </p>
                        <img
                          alt="pop"
                          src={`${process.env.REACT_APP_SERVER.replace(
                            '/app.php',
                            '',
                          )}/${pop}`}
                          className="w-full h-full"
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <Ufooter />
    </div>
  )
}
