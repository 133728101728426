import { FaBars, FaUserLock, FaUserPlus, FaUserCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { BiLogOut, BiToggleLeft, BiToggleRight } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import useLocalStorage from 'use-local-storage'
import axios from 'axios'
export default function Uheader({ toggleNav, user, logout }) {
  const navigate = useNavigate()
  const [theme, setTheme] = useLocalStorage('dark', null)
  const [totalbalance, setTotalbalance] = useState(0)
  function getTotalBalance() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=totalbalance'}`,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setTotalbalance(response.data)
      })
      .catch((error) => console.error(error))
  }
  useEffect(() => {
    getTotalBalance()
  }, [])
  useEffect(() => {
    document.documentElement.setAttribute('theme', theme)
  }, [theme])
  return (
    <>
      <header className="border-b-2 border-slate-100 flex flex-col justify-between py-5 border-slate-100">
        <div className="flex justify-between justify-between gap-2 flex-row lg:mx-20 mx-5">
          <div className="flex justify-between gap-6">
            <Link
              href="#"
              onClick={toggleNav}
              className="my-auto col-span-1 justify-self-end"
            >
              <FaBars className="my-auto text-center text-2xl" />
            </Link>
            <Link to="/">
              {' '}
              <img src="/logo.png" alt="logo" className="w-[75px]" />
            </Link>
          </div>
          <div className="flex justify-between gap-2">
            <span
              title="Change Theme"
              className="font-bold text-center my-auto uppercase"
            >
              {theme == 'dark' ? (
                <BiToggleLeft
                  onClick={() => setTheme('light')}
                  className="my-auto text-center text-4xl text-blue-500"
                />
              ) : (
                <BiToggleRight
                  onClick={() => setTheme('dark')}
                  className="my-auto text-center text-4xl text-gray-00"
                />
              )}
            </span>
            <Link
              href="#"
              title="Signout"
              onClick={() => logout()}
              className="my-auto col-span-1 justify-self-end"
            >
              <BiLogOut className="my-auto text-center text-3xl text-red-500" />
            </Link>
          </div>
        </div>{' '}
      </header>
      <div className="flex justify-between px-5 font-semibold py-2 bg-blue-900">
        <span className="uppercase">Portfolio Balance</span>
        <span
          className="my-auto p-1 bg-blue-500 rounded-lg"
          title="Total Balance"
        >
          {user?.cur}
          {Number(totalbalance).toLocaleString()}
        </span>
      </div>
    </>
  )
}
