import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useRef, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'
export default function Profile({ user, logout }) {
  const profilepic = useRef()
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  const uploadPpic = (e) => {
    e.preventDefault()

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=uploadprofilepic'}`,
      withCredentials: false,
      headers: {
        secrete: localStorage.getItem('secrete'),
        'Content-Type': 'multipart/form-data',
      },
      data: new FormData(e.target.closest('form')),
    })
      .then((response) => {
        if (response?.data?.type !== 'success') {
          Swal.fire('PROFILE PHOTO', response?.data?.msg, 'warning')
        } else {
          profilepic.current.src =
            process.env.REACT_APP_SERVER.replace('app.php', '') +
            response.data.ppic
        }
      })
      .catch((error) => console.error(error))
  }
  const updatePass = (e) => {
    e.preventDefault()
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER + '?r=updatepass'}`,
      withCredentials: false,
      headers: {
        secrete: localStorage.getItem('secrete'),
      },
      data: new FormData(e.target),
    })
      .then((response) => {
        Swal.fire(
          'PASSWORD UPDATE',
          response?.data?.msg,
          response?.data?.type === 'success' ? 'success' : 'warning',
        )
        if (response?.data?.type === 'success') {
          localStorage.setItem('secrete', response?.data?.secrete)
        }
      })
      .catch((error) => console.error(error))
  }
  console.log(user)
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/dashboard">Dashboard</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/profile">Profile</Link>
        </div>
        <h2 className="text-2xl mx-auto text-center">PROFILE</h2>
        <div className="flex text-slate-100 mx-auto lg:w-[60%] w-full mx-auto px-2">
          <div className="px-3 w-full flex flex-col bg-slate-800  py-6 border-2 border-slate-400 justify-center">
            <div className="flex flex-col py-3 text-center gap-2">
              <div className="py-5 px-2 lg:w-[200px] w-[140px] text-center mx-auto">
                <form
                  method="post"
                  encType="multipart/form-data"
                  onChange={(e) => uploadPpic(e)}
                >
                  <label htmlFor="ppic">
                    <img
                      ref={profilepic}
                      alt="profilepic"
                      src={
                        user?.profilepic == null
                          ? '/asset/img/profile.png'
                          : process.env.REACT_APP_SERVER?.replace(
                              'app.php',
                              '',
                            ) + user?.profilepic
                      }
                      className="w-full h-full rounded-full"
                    />
                  </label>
                  <input
                    id="ppic"
                    style={{ display: 'none' }}
                    type="file"
                    name="ppic"
                    className="border-2 border-slate-300 rounded-lg p-1 my-2"
                  />
                </form>
              </div>

              <div className="relative overflow-x-auto py-10">
                <table className="w-full text-sm text-left rtl:text-right text-black py-5">
                  <tbody>
                    <tr className="odd:bg-gray-300 odd:dark:bg-gray-900  even:bg-gray-500 even:dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        NAME
                      </th>
                      <td className="px-6 py-4 uppercase">{user?.name}</td>
                    </tr>
                    <tr className="odd:bg-gray-300 odd:dark:bg-gray-900  even:bg-slate-500 even:dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        EMAIL
                      </th>
                      <td className="px-6 py-4 uppercase">{user?.email}</td>
                    </tr>
                    <tr className="odd:bg-gray-300 odd:dark:bg-gray-900  even:bg-slate-500 even:dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        PHONE
                      </th>
                      <td className="px-6 py-4 uppercase">{user.phone}</td>
                    </tr>
                    <tr className="odd:bg-gray-300 odd:dark:bg-gray-900  even:bg-slate-500 even:dark:bg-gray-800">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        COUNTRY
                      </th>
                      <td className="px-6 py-4 uppercase">{user.country}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 px-5 lg:w-[58%] w-full mx-auto  flex flex-col bg-slate-800 mb-20 mt-5 py-6 border-2 border-slate-400 justify-center">
          <h2 className="text-2xl mx-auto text-center my-5">PASSWORD UPDATE</h2>
          <form
            id="password"
            className="flex flex-col gap-5 w-full px-4 "
            method="post"
            onSubmit={(e) => updatePass(e)}
          >
            <div>
              <label className="font-light uppercase my-1">Password:</label>
              <input
                type="password"
                name="pwd"
                className="w-10 bg-slate-800 border-white text-white w-full border-2 rounded-md py-2 px-1"
                required
              />
            </div>
            <div>
              <label className="font-light uppercase my-1">
                Password Confirmation:
              </label>
              <input
                type="password"
                name="cpwd"
                className="w-10 bg-slate-800 border-white text-white w-full border-2 rounded-md py-2 px-1"
                required
              />{' '}
            </div>
            <button
              type="submit"
              className="py-5 px-2 w-full border-2 border-slate-100 bg-slate-600 text-center "
            >
              UPDATE PASSWORD
            </button>
          </form>
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
