import { useEffect, useRef } from 'react'
import { FaBitcoin, FaEthereum, FaCreditCard } from 'react-icons/fa'
import { Link } from 'react-router-dom'
export default function Footer() {
  let year = useRef()
  useEffect(() => {
    year.current.innerHTML = new Date().getFullYear()
  })

  return (
    <>
      {' '}
      <footer className="lg:p-10 p-3 flex justify-between bottom-0 bg-gray-800 border-b-2 border-slate-100 flex lg:flex-row flex-col justify-between py-5 border-slate-100">
        <div className="p-4">
          <div className="logo">
            <p className="font-bold uppercase">{process.env.REACT_APP_NAME}</p>
            <img src="/logo.png" alt="logo" className="h-[50px]" />
          </div>
          <div>24/7 Customer Support</div>
          <div>Email: support@{process.env.REACT_APP_URL}</div>
        </div>

        <div className="flex flex-col  p-4">
          <p className="font-bold uppercase">Useful Links</p>
          <Link to="/payouts">Payouts</Link>
          <Link to="/contactus">Contact Us</Link>
          <Link to="/terms">Terms Of Service</Link>
        </div>
        <div className="flex flex-col line-1 p-4">
          <p className="font-bold uppercase">We Accept</p>
          <div className="flex gap-3">
            <FaBitcoin className="my-auto" /> <span>Bitcoin</span>
          </div>
          <div className="flex gap-3">
            <FaEthereum className="my-auto" />
            <span> Ethereum</span>
          </div>
          <div className="flex gap-3">
            <FaCreditCard className="my-auto" /> <span>Credit Card</span>
          </div>
        </div>
      </footer>
      <p className="mx-auto w-full bg-slate-900 px-10 py-4 text-center">
        &copy; <span ref={year}></span> {process.env.REACT_APP_NAME}
      </p>{' '}
    </>
  )
}
