import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import Home from './pages/Home'
import TFaq from './pages/TFaq'
import TAbout from './pages/TAbout'
import Experts from './pages/Experts'
import MFaq from './pages/MFaq'
import MAbout from './pages/MAbout'
import Datacenter from './pages/Datacenters'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import Profile from './pages/Profile'
import Contactus from './pages/Contactus'
import Payouts from './pages/Payouts'
import Signup from './pages/Signup'
import Signin from './pages/Signin'
import Reset from './pages/Reset'
import Mining from './pages/Mining'
import Trading from './pages/Trading'
import Mdeposit from './pages/Mdeposit'
import Tdeposit from './pages/Tdeposit'
import NewDeposit from './pages/Newdeposit'
import Deposit from './pages/Deposit'
import { useState, useEffect } from 'react'
import Payment from './pages/Payment'
import axios from 'axios'
import useLocalStorage from 'use-local-storage'
import Mcontracts from './pages/Mcontracts'
import Ycontracts from './pages/Ycontracts'
import Traders from './pages/Traders'
import Withdraw from './pages/Withdraw'
import YFaq from './pages/YFaq'
import YAbout from './pages/YAbout'
import Farming from './pages/Farm'
function App() {
  const curData = { $: 'USD', '€': 'EUR', '£': 'GBP' }
  const [mplans, setmPlans] = useLocalStorage([])
  const [yplans, setyPlans] = useLocalStorage([])
  const [tplans, settPlans] = useLocalStorage([])
  const [deposits, setDeposits] = useState([])
  const [activeDeposits, setActiveDeposits] = useState([])
  const [activeYields, setActiveYields] = useState([])
  const [pendingyields, setPendingYields] = useState({})
  const [traders, setTraders] = useState([])
  const [coins, setCoins] = useState([
    { symbol: 'BTC', rate: 0 },
    { symbol: 'ETH', rate: 0 },
    { symbol: 'LTC', rate: 0 },
    { symbol: 'XRP', rate: 0 },
    { symbol: 'ZEC', rate: 0 },
    { symbol: 'XMR', rate: 0 },
  ])
  const logout = () => {
    localStorage.removeItem('secrete')
    document.location.href = '/'
  }
  const [user, setUser] = useState({
    name: '',
    cur: '$',
    balance: 0,
    traders: [{ img: '/asset/img/traders/t1.png', name: 'josh', id: 1 }],
    trades: [
      {
        amount: 677,
        assets: 'BTCUSD',
        date: '03/12/2024',
        type: 'BUY',
        result: 'won',
      },
      {
        amount: 677,
        assets: 'BTCUSD',
        date: '03/12/2024',
        type: 'sell',
        result: 'lose',
      },
    ],
  })
  function getTraders() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=traders'}`,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setTraders(response.data)
      })
      .catch((error) => console.error(error))
  }

  function getDeposits() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=deposits'}`,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setDeposits(response.data)
      })
      .catch((error) => console.error(error))
  }

  function getActiveDeposits() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=activedeposits'}`,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setActiveDeposits(response.data)
      })
      .catch((error) => console.error(error))
  }

  function getActiveYields() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=activeyields'}`,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setActiveYields(response.data)
      })
      .catch((error) => console.error(error))
  }

  function getPendingYields() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=pendingyields'}`,
      withCredentials: false,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        if (response.data?.length > 0) {
          setPendingYields(response.data[0])
        } else {
          setPendingYields({})
        }
      })
      .catch((error) => console.error(error))
  }

  function getUser() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=user'}`,
      withCredentials: false,
      headers: {
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setUser(response.data)
      })
      .catch((error) => console.error(error))
  }
  function getRates() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=rates'}`,
      withCredentials: false,
      headers: {
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        let coinRates = []
        for (let [symbol, rate] of Object.entries(response.data)) {
          coinRates.push({ symbol: symbol, rate: rate })
        }
        setCoins(coinRates)
      })
      .catch((error) => console.error(error))
  }

  function getmPlans() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=mplans'}`,
      withCredentials: false,
      headers: {
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setmPlans(response.data)
      })
      .catch((error) => console.error(error))
  }
  function getyPlans() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=yplans'}`,
      withCredentials: false,
      headers: {
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        setyPlans(response.data)
      })
      .catch((error) => console.error(error))
  }

  function gettPlans() {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_SERVER + '?r=tplans'}`,
      withCredentials: false,
      headers: {
        secrete: localStorage.getItem('secrete'),
      },
    })
      .then((response) => {
        settPlans(response.data)
      })
      .catch((error) => console.error(error))
  }
  useEffect(() => {
    getUser()
    getRates()
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        {['index', '/'].map((i, k) => (
          <Route key={k} path={i} element={<Home />} />
        ))}
        <Route path="/yield/faqs" element={<YFaq />} />
        <Route path="/yield/about" element={<YAbout />} />
        <Route path="/trading/faqs" element={<TFaq />} />
        <Route path="/trading/about" element={<TAbout />} />
        <Route path="/trading/experts" element={<Experts />} />
        <Route path="/mining/faqs" element={<MFaq />} />
        <Route path="/mining/about" element={<MAbout />} />
        <Route path="/mining/datacenters" element={<Datacenter />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/payouts" element={<Payouts />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contactus" element={<Contactus />} />
        <Route path="/account/signup" element={<Signup />} />
        <Route path="/account/signin" element={<Signin />} />
        <Route path="/account/reset" element={<Reset />} />
        <Route
          path="/office/dashboard"
          element={
            <Trading
              user={user}
              getUser={getUser}
              traders={traders}
              getTraders={getTraders}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/mining"
          element={
            <Mining
              user={user}
              coins={coins}
              curData={curData}
              getUser={getUser}
              getRates={getRates}
              logout={logout}
            />
          }
        />

        <Route
          path="/office/mining/deposit"
          element={
            <Mdeposit
              mplans={mplans}
              getmPlans={getmPlans}
              user={user}
              coins={coins}
              curData={curData}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/yield/contracts"
          element={
            <Ycontracts
              yplans={yplans}
              getyPlans={getyPlans}
              activeYields={activeYields}
              user={user}
              coins={coins}
              curData={curData}
              getActiveYields={getActiveYields}
              getPendingYields={getPendingYields}
              getRates={getRates}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/yield/deposit"
          element={
            <Farming
              getPendingYields={getPendingYields}
              pendingyields={pendingyields}
              yplans={yplans}
              getyPlans={getyPlans}
              user={user}
              coins={coins}
              curData={curData}
              getRates={getRates}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/mining/contracts"
          element={
            <Mcontracts
              mplans={mplans}
              getmPlans={getmPlans}
              activeDeposits={activeDeposits}
              user={user}
              coins={coins}
              curData={curData}
              getActiveDeposits={getActiveDeposits}
              getRates={getRates}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/deposit"
          element={
            <Deposit
              user={user}
              coins={coins}
              curData={curData}
              deposits={deposits}
              getDeposits={getDeposits}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/trading/deposit"
          element={
            <Tdeposit
              user={user}
              coins={coins}
              curData={curData}
              tplans={tplans}
              gettPlans={gettPlans}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/mining/deposit/new"
          element={
            <NewDeposit
              user={user}
              coins={coins}
              curData={curData}
              mplans={mplans}
              getRates={getRates}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/trading/deposit/new"
          element={
            <NewDeposit
              user={user}
              coins={coins}
              curData={curData}
              tplans={tplans}
              getRates={getRates}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/deposit/pay"
          element={
            <Payment
              user={user}
              coins={coins}
              curData={curData}
              getRates={getRates}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/profile"
          element={<Profile user={user} logout={logout} />}
        />
        <Route
          path="/office/traders"
          element={
            <Traders
              user={user}
              getUser={getUser}
              traders={traders}
              getTraders={getTraders}
              logout={logout}
            />
          }
        />
        <Route
          path="/office/withdrawals"
          element={
            <Withdraw
              user={user}
              getUser={getUser}
              getRates={getRates}
              coins={coins}
              logout={logout}
            />
          }
        />
        <Route
          path={'/office/traders/:tid'}
          element={
            <Traders
              user={user}
              getUser={getUser}
              traders={traders}
              getTraders={getTraders}
              logout={logout}
            />
          }
        />
        <Route
          path={'/office/traders#mytraders'}
          element={
            <Traders
              user={user}
              getUser={getUser}
              traders={traders}
              getTraders={getTraders}
              logout={logout}
            />
          }
        />
        {/*/ 
          // <Route path="/office/deposit/pay" element={<Contact />} />
          // <Route path="*" element={<NoPage />} />*/}
      </Routes>
    </BrowserRouter>
  )
}

export default App
