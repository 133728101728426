import Uheader from '../components/Uheader'
import Ufooter from '../components/Ufooter'
import Unavbar from '../components/Unavbar'
import { useEffect, useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import moment from 'moment'
export default function Ycontracts({
  coins,
  user,
  curData,
  getActiveYields,
  activeYields,
  yplans,
  getyPlans,
  getPendingYields,
  logout,
}) {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  useEffect(() => {
    getPendingYields()
    getActiveYields()
    getyPlans()
  }, [])

  function addDays(date, days) {
    let dt = new Date(date)
    const newDate = new Date(dt.getTime() + days * 24 * 60 * 60 * 1000)
    return newDate
  }
  return (
    <div className="cont" data-theme="dark">
      <Uheader toggleNav={toggleNav} logout={logout} />
      <div>
        <Unavbar
          navState={navState}
          toggleNav={toggleNav}
          user={user}
          logout={logout}
        />
      </div>
      <div className="py-1 w-full">
        <div className="flex flex-2 gap-1 px-3 mt-5">
          <Link to="/office/dashboard">Dashboard</Link>
          <FaAngleRight className="my-auto text-lg" />
          <Link to="/office/yield/contracts">Yield Contracts</Link>
        </div>
        <h2 className="text-2xl mx-auto text-center font-semibold">
          YIELD CONTRACTS
        </h2>

        <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 text-slate-100 mx-auto w-full">
          {activeYields.length > 0 &&
            activeYields.map((c, k) => (
              <div title={c.pcur} key={k} className="mx-10">
                <div className="flex flex-col gap-1 mx-auto text-center">
                  <div className="px-10 w-full flex flex-col bg-slate-800  py-6 border-2 border-slate-400 justify-center">
                    <p className="mx-auto text-center">
                      Release Duration:{' '}
                      {moment(addDays(c.dt, c.duration)).fromNow()}
                    </p>
                    <div className="flex flex-col py-3 text-center">
                      <p
                        className={
                          'text-center mx-auto icon icon-' +
                          c.pcur.toLowerCase()
                        }
                        style={{ fontSize: '30px' }}
                      ></p>
                      <span className="text-2xl font-semibold my-2">
                        {c.plan} Plan
                      </span>
                    </div>
                    <div className="flex justify-center">
                      <img
                        alt="miner"
                        src={`/asset/img/miner/miner_${
                          yplans
                            .filter(
                              (m) =>
                                m?.symbol?.toLowerCase() ===
                                c?.coin?.toLowerCase(),
                            )
                            ?.indexOf(
                              yplans
                                .filter(
                                  (m) =>
                                    m?.symbol?.toLowerCase() ===
                                      c?.coin?.toLowerCase() &&
                                    m?.plan === c?.plan,
                                )
                                .shift(),
                            ) + 1
                        }.svg`}
                        className="w-[150px] h-auto invert"
                      />
                    </div>
                    <div className="flex flex-row gap-2 justify-center w-full text-lg font-semibold  border-slate-300 py-3">
                      <span className="text-center my-auto">INVESTED</span>
                      <span className="text-center my-auto">
                        {user.cur + Number(c.amount)?.toPrecision(4)}
                      </span>
                    </div>

                    <div className="w-full text-2xl font-semibold border-t-2 border-slate-300 py-3 text-sm"></div>
                    <div className="flex flex-row gap-2 justify-center w-full text-lg font-semibold  border-slate-300 py-3">
                      <span className="text-center my-auto">EARNED</span>
                      <span className="text-center my-auto">
                        {user.cur + Number(c.earnings)?.toPrecision(4)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {activeYields.length < 1 && (
            <div className="mx-auto w-full p-2 bg-blue-300 text-white">
              No Active Yield Contracts yet.
            </div>
          )}
        </div>
      </div>
      <Ufooter />
    </div>
  )
}
