import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
import { Carousel } from 'flowbite-react'
import { Link } from 'react-router-dom'
import { Accordion } from 'flowbite-react'
export default function Home() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="h-[750px] sm:h-[750px] xl:h-[650px] 2xl:h-800px py-1">
        <Carousel pauseOnHover className="hidecontrol">
          <div className="grid lg:grid-cols-2 grid-cols-1 animated fadeInRight slow">
            <div className="col-span-1 text-center my-auto leading-6 px-5">
              <p className="text-xl font-semibold">
                {process.env.REACT_APP_NAME} allows investors to copy trading
                experts in real time, automatically
              </p>
              <h1 className="text-2xl font-bold my-5">
                START COPY TRADING TODAY
              </h1>
              <p className="text-xl">
                {process.env.REACT_APP_NAME} Trading Platform is a trade copy
                service where you, as an investor, are able to follow trades of
                one or multiple expert traders without ever sending anyone your
                funds.
              </p>
              <div className="mt-[25px] flex gap-3 justify-center px-10">
                <Link
                  className="bg-blue-500 py-4 px-2 rounded-lg animated fadeInUp delay-1s"
                  to="account/signin"
                >
                  Start Trading
                </Link>
                <Link
                  className="bg-slate-500 py-4 px-2 rounded-lg  animated fadeInUp delay-2s"
                  to="/trading/about"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <img
              src="asset/img/Vector4.png"
              alt="Trading"
              className="lg:order-last order-first px-10"
            />
          </div>
          <div className="grid lg:grid-cols-2 grid-cols-1 animated fadeInRight slow">
            <div className="col-span-1 text-center my-auto leading-6 px-5">
              <p className="text-xl font-semibold">
                Join over 2,000,000 people with the world’s leading hashpower
                provider
              </p>
              <h1 className="text-2xl font-bold my-5">
                START BITCOIN MINING TODAY
              </h1>
              <p className="text-xl">
                It’s super simple - Your mining rigs are already set up and
                running. As soon as you’ve set up your account, you can start to
                mine your first coins using our Bitcoin cloud mining service!
              </p>
              <div className="mt-[25px] flex gap-3 justify-center px-10">
                <Link
                  className="bg-blue-500 py-4 px-2 rounded-lg animated fadeInUp delay-1s"
                  to="account/signin"
                >
                  Start Mining
                </Link>
                <Link
                  className="bg-slate-500 py-4 px-2 rounded-lg  animated fadeInUp delay-2s"
                  to="/mining/about"
                >
                  Learn More
                </Link>
              </div>
            </div>
            <img
              src="asset/img/Vector6.png"
              alt="Trading"
              className="lg:order-last order-first px-10"
            />
          </div>
        </Carousel>
      </div>
      <div className="bg-white py-10 text-blue-800">
        <h2 className="text-3xl font-bold text-center my-5">
          How Copy Trading Works
        </h2>
        <div className="flex justify-around text-center  font-semibold lg:flex-row flex-col text-lg py-50">
          <div className="flex flex-col py-5">
            {' '}
            <p className="my-4">Register and Fund Your Account</p>
            <img
              src="/asset/img/vector1.png"
              alt="register"
              className="w-[40%] mx-auto invert-[0.8]"
            />
          </div>

          <div className="flex flex-col text-xl py-5">
            {' '}
            <p className="my-4">Copy One or More Expert Traders</p>
            <img
              src="/asset/img/vector3.png"
              alt="register"
              className="w-[40%] mx-auto invert-[0.8]"
            />
          </div>

          <div className="flex flex-col py-5">
            {' '}
            <p className="my-4"> Watch Your Profits Grow</p>
            <img
              src="/asset/img/vector2.png"
              alt="register"
              className="w-[40%] mx-auto invert-[0.8]"
            />
          </div>
        </div>
        <div className="lg:px-20 px-5 text-lg">
          <p className="font-light p-5 text-slate-700">
            Here is how the copier works: You, as an investor, simply select an
            expert or experts that you want to copy trades from. Once you are
            signed up, this is the only action needed on your part.
          </p>
          <p className="font-light p-5 text-slate-700">
            Once you’ve taken care of the above, you are all set. There are no
            codes that you need to run or signals for you to manually input. Our
            software will handle the trade copying automatically on your behalf.
            We monitor your experts trading activity and as soon as there is a
            trade, we calculate all the necessary parameters and execute the
            trade.
          </p>
          <p className="font-light p-5 text-slate-700">
            The only thing you have to make sure of is that you have enough
            funds available in your trading account. When the expert exits a
            position, you too will exit it. Automatically.
          </p>
        </div>
      </div>
      <div className="bg-white  py-10 text-slate-600">
        <div className="flex flex-between lg:flex-row flex-col">
          <div className="flex flex-col gap-5 text-center">
            <h1
              className="material-icons text-blue-800 notranslate mx-auto"
              style={{ fontSize: '8rem' }}
            >
              dns
            </h1>
            <h2 className="text-2xl font-semibold">DIVERSIFY</h2>
            <p className="mx-10 text-lg">
              You can follow as many expert traders as you'd like to diversify
              your trading portfolio.
            </p>
          </div>
          <div
            className="flex  flex-col wow fadeInUp text-center"
            data-wow-delay="0.2s"
          >
            <h1
              className="material-icons text-blue-800 notranslate mx-auto"
              style={{ fontSize: '8rem' }}
            >
              security
            </h1>
            <h2 className="text-2xl font-semibold">SECURE TRANSACTIONS</h2>
            <p className="mx-10 text-lg">
              We take security and privacy seriously. Trading is processed
              through our secure servers.
            </p>
          </div>
          <div
            className="flex flex-col justify-center text-center wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <h1
              className="material-icons text-blue-800 notranslate mx-auto"
              style={{ fontSize: '8rem' }}
            >
              support_agent
            </h1>
            <h2 className="text-2xl font-semibold">24/7 CUSTOMER SUPPORT</h2>
            <p className="mx-10 text-lg">
              Being there for you at every step of the way is our number one
              priority.
            </p>
          </div>
        </div>
      </div>
      <div className="bg-dark-800 py-10">
        <h2 className="text-3xl font-bold text-center my-5">
          How Cloud Mining Works
        </h2>
        <div className="flex lg:flex-row flex-col justify-between px-2">
          <div className="flex flex-col lg:w-[50%] w-full justify-center text-lg">
            <p className="font-light p-5">
              {process.env.REACT_APP_NAME} offers you a smart and easy way to
              mine cryptocurrencies. Our Bitcoin mining system is suitable for
              those who are new to the world of cryptocurrencies, as well as for
              cryptocurrency experts and large-scale miners.{' '}
              {process.env.REACT_APP_NAME} is the world’s first large scale
              multi-algorithm cloud mining service, offering an alternative to
              those who would like to engage in Bitcoin and altcoin mining.
            </p>
            <p className="font-light p-5">
              First of all when you decide for a contract with us you can forget
              about the complicated set up and maintenance of a mining rig.
              Secondly, you are mining online, without having loud mining
              machines in your home that need a lot of space, intense cooling,
              and power consumption sources.
            </p>
            <Link
              className="bg-blue-500 p-3 text-white rounded-lg mx-auto"
              href="mining/about"
            >
              Learn more
            </Link>
          </div>
          <div className="px-5 my-10 lg:w-[50%] w-full">
            <iframe
              title="Bitcoin Explained"
              className="w-full min-h-[450px] px-2"
              src="https://www.youtube.com/embed/GmOzih6I1zs"
              allowFullScreen="yes"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="bg-white text-slate-700 py-10">
        <div className="flex flex-between lg:flex-row flex-col">
          <div className="flex flex-col gap-5 text-center">
            <h1
              className="material-icons text-blue-800 notranslate mx-auto"
              style={{ fontSize: '8rem' }}
            >
              dns
            </h1>
            <h2 className="text-2xl font-semibold">
              YOUR HARDWARE IS ALREADY RUNNING
            </h2>
            <p className="mx-10 text-lg">
              Don’t wrestle with rig assembly and hot, noisy miners at home. We
              have the fastest bitcoin mining hardware running for you already
            </p>
          </div>
          <div
            className="flex  flex-col wow fadeInUp text-center"
            data-wow-delay="0.2s"
          >
            <h1
              className="material-icons text-blue-800 notranslate mx-auto"
              style={{ fontSize: '8rem' }}
            >
              copyright
            </h1>
            <h2 className="text-2xl font-semibold">
              MINE ALTERNATIVE CRYPTOCURRENCIES
            </h2>
            <p className="mx-10 text-lg">
              You can mine any cryptocurrency available in our catalogue! Switch
              your mining power on the fly for all the coins using our bitcoin
              mining website
            </p>
          </div>
          <div
            className="flex flex-col justify-center text-center wow fadeInUp"
            data-wow-delay="0.4s"
          >
            <h1
              className="material-icons notranslate text-blue-800  mx-auto"
              style={{ fontSize: '8rem' }}
            >
              today
            </h1>
            <h2 className="text-2xl font-semibold">
              GET YOUR FIRST MINING OUTPUT TODAY
            </h2>
            <p className="mx-10 text-lg">
              You will get periodic mining outputs to your designated wallet,
              try our Bitcoin mining platform now
            </p>
          </div>
        </div>
      </div>
      <div className="bg-blue-900 py-10">
        <div className="text-center">
          <p className="text-2xl font-bold">Frequently Asked Questions</p>
        </div>
        <p className="text-sm text-center mx-auto uppercase text-2xl font-bold my-5">
          Copy Trading
        </p>
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:px-20 px-5 border-2 border-slate-400 rounded-lg mx-5">
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>Who are the experts?</Accordion.Title>
              <Accordion.Content>
                We carefully select expert applicants. We get to know them as a
                trader and examine their trading performance over a period of
                time. We also tend to look for expert who already have a
                following to further confirm their competence (social proof).
                You can also read about every expert on their individual
                performance pages. For example, you might want to have a look at
                coinwarz.com. Remember that some of our products have a daily
                maintenance fee which must be deducted from the daily Trading
                rewards. Please note that the website is an independent source
                of reference and not related to {process.env.REACT_APP_NAME} in
                any manner.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>

          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>
                What is the recommended amount to start with?
              </Accordion.Title>
              <Accordion.Content>
                The amount you start up with determines your profit, so
                basically the higher the amount you start with, the higher your
                profits.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>How does this work?</Accordion.Title>
              <Accordion.Content>
                Here is how the copier works: You, as an investor, simply select
                an expert or experts that you want to copy trades from. Once you
                are signed up, this is the only action needed on your part. Once
                you’ve taken care of the above, you are all set. There are no
                codes that you need to run or signals for you to manually input.
                Our software will handle the trade copying automatically on your
                behalf. We monitor your experts trading activity and as soon as
                there is a trade, we calculate all the necessary parameters and
                execute the trade. The copier works based on trade percent
                amount. So, for example, if your expert takes a position in XYZ
                coin for a total of 10% of his account value and you are 100%
                allocated to that expert, then the copier will also execute a
                trade in your account in the amount of 10% of your account
                value. The only thing you have to make sure of is that you have
                enough available base currency that your expert trades with, in
                your trading account. How much is enough? First, you must meet
                the exchanges minimum order amount (let’s say about $10 per
                trade to be safe). That means that if your expert executes a 5%
                order, you must have at least $300 in your account total value
                (at 100% expert allocation as an example). This also means that
                you need to have at least 10% or higher in available base
                currency to avoid missed trades. When the expert exits a
                position, you too will exit it. Automatically. You can also
                change allocation at any time.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>What are the fees?</Accordion.Title>
              <Accordion.Content>
                We do not charge you any setup or maintenance fees, each trader
                gets a set precentage of the profit they make, make sure you
                check this percentage before copying any trader.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>
        <p className="text-sm text-center mx-auto uppercase text-2xl font-bold my-5">
          Mining
        </p>
        <div className="grid lg:grid-cols-2 grid-cols-1 px-5  border-2 border-slate-400 rounded-lg mx-5">
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>
                How does Bitcoin mining work with {process.env.REACT_APP_NAME}?
              </Accordion.Title>
              <Accordion.Content>
                It’s quick and very easy! As soon as we receive your payment
                your contract will be added to your profile, and you can
                immediately start mining. Depending on the blockchain algorithm
                you select and the associated mining service agreement you enter
                into, you can either mine native cryptocurrencies directly or
                allocate your hashpower to other cryptocurrencies (marked with
                AUTO), and even choose a specific allocation for them. For
                example: 60% LTC, 20% BTC and 20% DOGE. The first mining output
                is released after 48 hours, and then a daily mining output will
                follow.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>Are you mining for yourself?</Accordion.Title>
              <Accordion.Content>
                Besides the fact that we ourselves mine with the very same
                hardware that we offer to our clients, our capital is limited.
                We believe that Bitcoin and Altcoin mining is one of the best
                ways to receive Cryptocurrencies, however, we do not want to
                “put all our eggs in one basket”.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>
                Where is your mining farm located?
              </Accordion.Title>
              <Accordion.Content>
                For security reasons, we do not disclose the exact location of
                our mining farms. As of April 2015, we are operating several
                mining farms that are located in Europe, America and Asia.
                Electricity cost and availability of cooling are important, but
                not the only criteria. See our Datacenters page for more
                information.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>
                How do your ether contracts work?
              </Accordion.Title>
              <Accordion.Content>
                When you purchase an Ether mining contract, you buy a share of a
                GPU (“graphics processing unit“) to mine Ethereum. The hashing
                algorithm used to mine Ether is called Ethash (or
                Dagger-Hashimoto). Ethash requires the so called DAG file in
                order to perform the calculations. This file grows over time,
                which means that more operations for each hashing operation need
                to be performed. This means that the effective hashrate
                decreases a little each time the DAG file grows. This has to be
                considered when comparing the theoretical output of your
                hashpower. Online calculators do not take this into account.
                Also, please keep in mind that some of our products have a
                maintenance fee attached.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>What are the expected returns?</Accordion.Title>
              <Accordion.Content>
                Bitcoin and altcoin mining depends highly on the prices and
                network difficulties of the Cryptocurrencies that you are
                mining, which cannot be predicted. It would not “look right” if
                we would provide data on the mining performance and sell
                contracts at the same time. Therefore, we recommend all our
                clients to do their own research and do their own performance
                calculation. For example, you might want to have a look at
                coinwarz.com. Remember that some of our products have a daily
                maintenance fee which must be deducted from the daily mining
                rewards. Please note that the website is an independent source
                of reference and not related to {process.env.REACT_APP_NAME} in
                any manner.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>
                Which pools are you using for mining?
              </Accordion.Title>
              <Accordion.Content>
                We do not publish a list of pools we are using. Our main
                criteria for a good pool are: reliability, fee structure and
                reject rate. Going forward we will solo-mine a few coins (and
                pass the fee savings to our users!). Our internal policy is: “be
                a good crypto citizen”. This means, that we will at least use
                two different pools (in some cases we use up to four) for each
                coin. This is to preserve the decentralized nature of the crypto
                networks! If we become aware that a pool is getting close to 50%
                share, we will switch away from it and use a backup instead.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>Isn't buying hardware cheaper?</Accordion.Title>
              <Accordion.Content>
                On the first glance one might think that buying miner X is
                slightly cheaper than hosted mining. However, there are a number
                of “hidden” costs related to purchasing and maintaining a miner
                that change the economics significantly: Shipping costs: few
                merchants will cover this, but in most cases they will charge
                you extra. Customs dues / VAT: if it is an international
                delivery you will likely have to pay customs dues and VAT;
                generally this has a huge impact on the total cost of your
                mining equipment. Delivery time: when you pay for your device
                you have to wait until the miner is packaged and shipped; if it
                is held up in the customs this can take weeks. And during that
                time you cannot mine at all (“opportunity costs”). You have to
                wait and hope. And if the merchant has a stock shortage; it is
                you who has to wait. With{process.env.REACT_APP_NAME} you pay
                and get your hashpower immediately online. No delays! Costs for
                additional equipment: when buying miners from larger retailers
                you often have to buy additional equipment like an extra PSU
                that matches the electricity specs for your country, control
                units and specific shelves to store them efficiently. This adds
                up significantly to the overall price for hashpower. Setting the
                system up: when the device finally arrived you have to assemble
                and configure it. Beginners often underestimate the amount of
                work and technical knowledge that is needed to bring the device
                up and optimize its settings. People that used the first ASICs
                spend days and weeks on it. In monetary terms this costs you
                twice: (i) your time and (ii) lost mining opportunity.
                Maintenance: hardware failures, outages and crashes could lead
                to downtimes between seconds and weeks. If your unit breaks you
                have to return it and start from scratch (by the way: check the
                guarantee time of your miner; it is often less than a year).
                With {process.env.REACT_APP_NAME} you have a guaranteed uptime
                of 100%: in case one of your units fails another unit will be
                switched over and start mining for you the very same second.
                Electricity: having the machines at home not only brings heat
                and noise, but also an increase of your electricity bill; an
                important factor that many underestimate. Please do not get us
                wrong: we do not want to discourage anyone from mining at home!
                We are often asked about prices and realized that many users do
                not know about all the hidden costs. If you compare the true
                costs, you will notice that our prices are very competitive. We
                want to provide you the best possible mining experience, so that
                you can focus on mining your favorite coins (and we take care of
                the rest).
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
          <Accordion className="border-0 my-2">
            <Accordion.Panel>
              <Accordion.Title>
                How can I mine different coins at the same time?
              </Accordion.Title>
              <Accordion.Content>
                {process.env.REACT_APP_NAME} allows its clients to mine
                different types of coins at the same time. You decide which
                coins you prefer and you can allocate hashpower accordingly.
                Each mining algorithm is set up with a default delivery
                cryptocurrency. The {process.env.REACT_APP_NAME}
                Advanced Auto-Allocation (in short “AUTO”) will apply to all
                cryptocurrencies which cannot be mined directly. In the user
                interface go to “Mining Allocation” and choose the hashpower
                allocation that is best for you. When you are done press “save
                allocation”. Remember that the cryptocurrencies marked with
                (AUTO) are not directly mined, as mentioned in the “What coins
                can I mine?” section of this Customer Service page. In order to
                get the mining output, please ensure to add your wallet
                address(es) to your {process.env.REACT_APP_NAME} profile. Find
                out more details about how to add your wallet(s) in the next
                section.
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>
      </div>
      <div className="bg-white text-slate-600 py-10">
        <h2 className="text-xl text-center font-bold">Happy Customers</h2>

        <div className="h-[250px] sm:h-[250px] xl:h-[350px] 2xl:h-300px py-1">
          <Carousel pauseOnHover>
            <div className="flex flex-col gap-4 justify-center">
              <img
                src="asset/img/testimonials/1.jpg"
                className="h-[100px] w-[100px] rounded-full mx-auto"
                alt="customer"
              />
              <div className="text-center">
                <p className="mx-auto text-center">
                  I Have been using {process.env.REACT_APP_NAME} for about 8
                  years. The service has always been reliable.
                </p>
                <h3>George</h3>
              </div>
            </div>

            <div className="flex flex-col gap-4 justify-center">
              <img
                src="asset/img/testimonials/2.jpg"
                className="h-[100px] w-[100px] rounded-full mx-auto"
                alt="customer"
              />
              <div className="text-center">
                <p className="mx-auto text-center">
                  Overall very professional, no concerns, very happy with
                  service and attention
                </p>{' '}
                <h3>Julian</h3>
              </div>
            </div>

            <div className="flex flex-col gap-4 justify-center">
              <img
                src="asset/img/testimonials/3.jpg"
                className="h-[100px] w-[100px] rounded-full mx-auto"
                alt="customer"
              />
              <div className="text-center">
                <p className="mx-auto text-center">
                  Excellent service! Very clear explanation and support through
                  all the process
                </p>{' '}
                <h3>Pablo</h3>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
      <Footer />
    </div>
  )
}
