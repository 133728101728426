import { Link } from 'react-router-dom'
import { Accordion } from 'flowbite-react'
export default function Unavbar({ toggleNav, navState, user, logout }) {
  return (
    <div
      className={`navbar px-3 py-5 max-h-100vh overflow-auto max-w-[300px] sidebar ${navState}`}
    >
      <div className="flex justify-end mr-[-5px] text-red-500 hover:text-red-300">
        {' '}
        <span
          onClick={toggleNav}
          className="material-icons notranslate justify-self-end cursor-pointer"
        >
          close
        </span>
      </div>
      <div className="col-span-1">
        <ul className="">
          <li>
            <Link to="/office/profile">
              {' '}
              <img
                alt="profilepic"
                src={
                  user?.profilepic == null
                    ? '/asset/img/profile.png'
                    : process.env.REACT_APP_SERVER?.replace('app.php', '') +
                      user?.profilepic
                }
                className="w-auto h-[80px] rounded-full mx-auto"
              />
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/dashboard"
            >
              <span className="material-icons notranslate">dashboard</span>
              Dashboard{' '}
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/office/deposit">
              <span className="material-icons notranslate">input</span>
              Deposit
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/deposit#history"
            >
              <span className="material-icons notranslate">list</span>
              Deposit History
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/office/mining">
              <span className="material-icons notranslate">copyright</span>
              Mining Dashboard
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/mining/contracts"
            >
              <span className="material-icons notranslate">hourglass_top</span>
              Mining Contracts
            </Link>
          </li>

          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/yield/deposit"
            >
              <span className="material-icons notranslate">settings</span>
              Yield Contracts
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/yield/contracts"
            >
              <span className="material-icons notranslate">
                settings_input_component
              </span>
              My Yield Contracts
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/dashboard"
            >
              <span className="material-icons notranslate">assessment</span>
              Trading
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/dashboard#tradehistory"
            >
              <span className="material-icons notranslate">list</span>
              Trading History
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/office/traders">
              <span className="material-icons notranslate">
                supervisor_account
              </span>
              All Traders
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/traders#mytraders"
            >
              <span className="material-icons notranslate">
                supervised_user_circle
              </span>
              Copied Traders
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/withdrawals"
            >
              <span className="material-icons notranslate">payment</span>
              Withdrawals
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link className="sidenav-close flex gap-1 p-2" to="/office/profile">
              <span className="material-icons notranslate">account_circle</span>
              My Profile
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/profile#password"
            >
              <span className="material-icons notranslate">lock</span>
              Update Password
            </Link>
          </li>
          <li className="odd:bg-slate-800 even:bg-dark-800">
            <Link
              className="sidenav-close flex gap-1 p-2"
              to="/office/profile#password"
              onClick={() => logout()}
            >
              <span className="material-icons notranslate">
                power_settings_new
              </span>
              SignOut
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
