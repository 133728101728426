import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
export default function YAbout() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 bg-white text-slate-800">
        <div>
          {' '}
          <div className="lg:px-20 p-5 text-left">
            <h2 className="text-2xl uppercase font-extrabold text-center">
              About Yield Farming
            </h2>
            <p className="font-light p-5 indent-1">
              Yield farming is simply a means of maximizing your returns by
              depositing your crypto or digital assets into a decentralized
              application (dApp).Earning through Yield farming involves a
              variety of practices all aimed at generating maximum returns for
              investors.
            </p>

            <div className="font-light p-5">
              <h2 className="text-xl font-semibold text-center">
                INVESTMENT OPTIONS IN YIELD FARMING
              </h2>
              <ul className="list-disc gap-3 flex flex-col mt-5">
                <li>
                  Liquidity Providers: Cryptocurrency is not as liquid as the
                  stock/forex market because often less is traded daily.
                  Investors who provide liquidity deposit tokens to help traders
                  enter and exit positions with leveraged positions.In turn, a
                  fee is charged on each trade which serves as returns given to
                  investors.
                </li>
                <li>
                  {' '}
                  Lenders: We also lend cryptocurrencies to borrowers using a
                  smart contract, eventually realizing yield from the interest
                  paid on the loan. Borrowers On the other side, there are
                  borrowers—market participants who use one token in a pair as
                  collateral and are lent the other token of the pair. This
                  activity allows the users to farm the yield with the borrowed
                  coin(s). This means the farmer retains their initial holding,
                  which could rise in value, and earns yield on their borrowed
                  coins.{' '}
                </li>
                <li>
                  Stakers: We stake your deposited cryptos and you earn rewards
                  based on the amount you have staked. On proof-of-stake (PoS)
                  blockchains, investors receives fees (depending on the payout
                  scheme and how much they have staked) if they stake their
                  cryptocurrency to a staking pool or another validator who pays
                  rewards.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex bg-blue-800 text-white justify-around text-center  font-semibold lg:flex-row flex-col text-lg py-50">
            <div className="flex flex-col py-5">
              {' '}
              <p className="my-4">Register and Fund Your Account</p>
              <img
                src="/asset/img/vector1.png"
                alt="register"
                className="w-[60%] mx-auto invert-[0.8]"
              />
            </div>

            <div className="flex flex-col text-xl py-5">
              {' '}
              <p className="my-4">Choose A Yield Option</p>
              <img
                src="/asset/img/vector3.png"
                alt="register"
                className="w-[60%] mx-auto invert-[0.8]"
              />
            </div>

            <div className="flex flex-col py-5">
              {' '}
              <p className="my-4"> Watch Your Profits Grow</p>
              <img
                src="/asset/img/vector2.png"
                alt="register"
                className="w-[60%] mx-auto invert-[0.8]"
              />
            </div>
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-center">Our Role</h2>
        <p className="font-light p-5">
          Once you choose a yield option, We take things up from there.We find
          the best investment to put your deposit to work on, consequently
          giving you the best returns possible.
        </p>
      </div>
      <Footer />
    </div>
  )
}
