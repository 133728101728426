import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
import { MdEmail } from 'react-icons/md'
import { Accordion } from 'flowbite-react'
export default function MFaq() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 bg-blue-800 text-white">
        <div className="lg:px-20 p-5 text-center">
          <h2 className="text-2xl">Frequently Asked Questions</h2>
          <p>Mining</p>
          <div className="grid  grid-cols-1 lg:px-20 px-5 border-2 border-slate-400 rounded-lg mx-5">
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  How does Bitcoin mining work with {process.env.REACT_APP_NAME}
                </Accordion.Title>
                <Accordion.Content>
                  It’s quick and very easy! As soon as we receive your payment
                  your contract will be added to your profile, and you can
                  immediately start mining. Depending on the blockchain
                  algorithm you select and the associated mining service
                  agreement you enter into, you can either mine native
                  cryptocurrencies directly or allocate your hashpower to other
                  cryptocurrencies (marked with AUTO), and even choose a
                  specific allocation for them. For example: 60% LTC, 20% BTC
                  and 20% DOGE. The first mining output is released after 48
                  hours, and then a daily mining output will follow.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>Are you mining for yourself?</Accordion.Title>
                <Accordion.Content>
                  Besides the fact that we ourselves mine with the very same
                  hardware that we offer to our clients, our capital is limited.
                  We believe that Bitcoin and Altcoin mining is one of the best
                  ways to receive Cryptocurrencies, however, we do not want to
                  “put all our eggs in one basket”.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  Where is your mining farm located?
                </Accordion.Title>
                <Accordion.Content>
                  For security reasons, we do not disclose the exact location of
                  our mining farms. As of April 2015, we are operating several
                  mining farms that are located in Europe, America and Asia.
                  Electricity cost and availability of cooling are important,
                  but not the only criteria. See our Datacenters page for more
                  information.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  How do your ether contracts work?
                </Accordion.Title>
                <Accordion.Content>
                  When you purchase an Ether mining contract, you buy a share of
                  a GPU (“graphics processing unit“) to mine Ethereum. The
                  hashing algorithm used to mine Ether is called Ethash (or
                  Dagger-Hashimoto). Ethash requires the so called DAG file in
                  order to perform the calculations. This file grows over time,
                  which means that more operations for each hashing operation
                  need to be performed. This means that the effective hashrate
                  decreases a little each time the DAG file grows. This has to
                  be considered when comparing the theoretical output of your
                  hashpower. Online calculators do not take this into account.
                  Also, please keep in mind that some of our products have a
                  maintenance fee attached.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  What are the expected returns?
                </Accordion.Title>
                <Accordion.Content>
                  Bitcoin and altcoin mining depends highly on the prices and
                  network difficulties of the Cryptocurrencies that you are
                  mining, which cannot be predicted. It would not “look right”
                  if we would provide data on the mining performance and sell
                  contracts at the same time. Therefore, we recommend all our
                  clients to do their own research and do their own performance
                  calculation. For example, you might want to have a look at
                  coinwarz.com. Remember that some of our products have a daily
                  maintenance fee which must be deducted from the daily mining
                  rewards. Please note that the website is an independent source
                  of reference and not related to {process.env.REACT_APP_NAME}{' '}
                  in any manner.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  Which pools are you using for mining?
                </Accordion.Title>
                <Accordion.Content>
                  We do not publish a list of pools we are using. Our main
                  criteria for a good pool are: reliability, fee structure and
                  reject rate. Going forward we will solo-mine a few coins (and
                  pass the fee savings to our users!). Our internal policy is:
                  “be a good crypto citizen”. This means, that we will at least
                  use two different pools (in some cases we use up to four) for
                  each coin. This is to preserve the decentralized nature of the
                  crypto networks! If we become aware that a pool is getting
                  close to 50% share, we will switch away from it and use a
                  backup instead.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>

            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  How can I mine different coins at the same time?
                </Accordion.Title>
                <Accordion.Content>
                  {process.env.REACT_APP_NAME} allows its clients to mine
                  different types of coins at the same time. You decide which
                  coins you prefer and you can allocate hashpower accordingly.
                  Each mining algorithm is set up with a default delivery
                  cryptocurrency. The
                  {process.env.REACT_APP_NAME} Advanced Auto-Allocation (in
                  short “AUTO”) will apply to all cryptocurrencies which cannot
                  be mined directly. In the user interface go to “Mining
                  Allocation” and choose the hashpower allocation that is best
                  for you. When you are done press “save allocation”. Remember
                  that the cryptocurrencies marked with (AUTO) are not directly
                  mined, as mentioned in the “What coins can I mine?” section of
                  this Customer Service page. In order to get the mining output,
                  please ensure to add your wallet address(es) to your{' '}
                  {process.env.REACT_APP_NAME} profile. Find out more details
                  about how to add your wallet(s) in the next section.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  Which pools are you using for mining?
                </Accordion.Title>
                <Accordion.Content>
                  We do not publish a list of pools we are using. Our main
                  criteria for a good pool are: reliability, fee structure and
                  reject rate. Going forward we will solo-mine a few coins (and
                  pass the fee savings to our users!). Our internal policy is:
                  “be a good crypto citizen”. This means, that we will at least
                  use two different pools (in some cases we use up to four) for
                  each coin. This is to preserve the decentralized nature of the
                  crypto networks! If we become aware that a pool is getting
                  close to 50% share, we will switch away from it and use a
                  backup instead.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
          <p className="mx-auto text-center mt-10">Other Enquiries?</p>
          <div className="flex gap-2 text-center justify-center">
            {' '}
            <MdEmail className="text-lg my-auto" />
            <span>
              support@
              {process.env.REACT_APP_URL}
            </span>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
