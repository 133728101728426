import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { useState } from 'react'
import { MdEmail } from 'react-icons/md'
import { Accordion } from 'flowbite-react'
export default function YFaq() {
  const [navState, setNavState] = useState('hide')
  const toggleNav = () => {
    setNavState(navState === 'hide' ? 'show' : 'hide')
  }
  return (
    <div className="cont" data-theme="dark">
      <Header toggleNav={toggleNav} />
      <div>
        <Navbar navState={navState} toggleNav={toggleNav} />
      </div>
      <div className="py-1 bg-darl-800">
        <div className="lg:px-20 p-5 text-center">
          <h2 className="text-2xl">Frequently Asked Questions</h2>
          <p>Yield Farming</p>
          <div className="grid  grid-cols-1 lg:px-20 px-5 border-2 border-slate-400 rounded-lg mx-5">
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>What is APY in yield farming?</Accordion.Title>
                <Accordion.Content>
                  APY stands for annualized percentage yield. It is a figure
                  showing the rate of return on investment annually, including
                  compounding returns.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>
                  How profitable is yield farming?
                </Accordion.Title>
                <Accordion.Content>
                  Yield farming has potentials of between 100% APY over 1000%
                  APY depending on a number of factors.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>How does this work?</Accordion.Title>
                <Accordion.Content>
                  Yield Farming involves investment of crypto assets into
                  various profitable options which includes but is not limited
                  to lending,staking,liquidity pools etc to maximize returns.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
            <Accordion className="border-0 my-2">
              <Accordion.Panel>
                <Accordion.Title>How are returns estimated?</Accordion.Title>
                <Accordion.Content>
                  Returns are estimated based on investment returns as the bulk
                  returns are divided among all investors based on their
                  investments.
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
          <p className="mx-auto text-center mt-10">Other Enquiries?</p>
          <div className="flex gap-2 text-center justify-center">
            {' '}
            <MdEmail className="text-lg my-auto" />
            <span>
              support@
              {process.env.REACT_APP_URL}
            </span>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
